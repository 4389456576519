import * as React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { PermissionPage } from 'ui-lib';

import { useKeyStore, useRoutesStore, useStores } from '../../../store';
import { KeyInventoryPage } from './inventory';
import { KeyDistributionPage } from './distribution';
import { KcvPspKeyPage } from './kcv-psp-keys';

export enum KEY_ROUTE_CODES {
    ROOT = 'KEYS',
    INVENTORY = 'KEYS_INVENTORY',
    DISTRIBUTION = 'KEYS_DISTRIBUTION',
    VERIFY = 'KEYS_VERIFY',
}

export function KeyManagementPage() {
    const navigate = useNavigate();
    const keyStore = useKeyStore();
    const routeStore = useRoutesStore();
    const { permissionStore } = useStores();

    const rootRoute = routeStore.getRoute(KEY_ROUTE_CODES.ROOT);
    const inventoryRoute = routeStore.getRoute(KEY_ROUTE_CODES.INVENTORY);
    const distributionRoute = routeStore.getRoute(KEY_ROUTE_CODES.DISTRIBUTION);
    const verifyRoute = routeStore.getRoute(KEY_ROUTE_CODES.VERIFY);

    React.useEffect(() => {
        keyStore.loadKeys(true);
    }, []);

    return (
        <Routes>
            <Route
                path={inventoryRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title='Inventory'
                    >
                        <KeyInventoryPage />
                    </PermissionPage>
                }
            />
            <Route
                path={distributionRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title='Distribution'
                    >
                        <KeyDistributionPage onCancel={() => navigate(inventoryRoute.path)} />
                    </PermissionPage>
                }
            />
            <Route
                path={verifyRoute.trimRootPath(rootRoute.path)}
                element={
                    <PermissionPage
                        permissionCodes={permissionStore.permissionCodes}
                        routes={routeStore.routes}
                        title='Verify keys'
                    >
                        <KcvPspKeyPage />
                    </PermissionPage>
                }
            />
        </Routes>
    );
}
