import React from 'react';
import { observer } from 'mobx-react';

import { DangerAlert, TableListingPage, IBasicTableColumn } from 'ui-lib';

import { IDisplayKey } from '../../../store/key/key-store';
import { confirmDelete } from '../utils';
import { UploadKeysComponent } from './upload-keys';

import manageKeyCss from './keys.css';
import { useKeyStore } from 'store';
import { getErrorMessage } from 'common-utils';
import { PSP } from 'dto/key-management/key-dto';

export enum KEY_INVENTORY_PAGE {
    LIST,
    ADD,
}

function deleteKey(id: number, action: any) {
    confirmDelete(id, 'Do you want to delete this key?', action);
}

export const KeyInventoryPage = observer(() => {
    const keyStore = useKeyStore();
    const [currentPage, setCurrentPage] = React.useState<KEY_INVENTORY_PAGE>(KEY_INVENTORY_PAGE.LIST);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const onDelete = async (id: number) => keyStore.deleteKey(id).catch((err) => setErrorMessage(getErrorMessage(err)));

    const onAdd = () => setCurrentPage(KEY_INVENTORY_PAGE.ADD);

    const columns: IBasicTableColumn[] = [
        {
            code: 'psp',
            title: 'PSP',
            dataIndex: 'psp',
            filters: Object.values(PSP).map((item) => ({ value: item, text: item })),
            sorter: (a, b) => a.psp.localeCompare(b.psp),
            onFilter: (psp: string, customerKey: IDisplayKey) => customerKey.psp === psp,
        },
        {
            code: 'keyId',
            title: 'Key id',
            dataIndex: 'keyId',
            sorter: (a, b) => a.keyId - b.keyId,
            textSearchPlaceholder: 'Search key id',
            onFilter: (keyId: number, customerKey: IDisplayKey) => customerKey.keyId == keyId,
        },
        {
            code: 'keyName',
            title: 'Key name',
            dataIndex: 'keyName',
            sorter: (a, b) => a.keyName.localeCompare(b.keyName),
            textSearchPlaceholder: 'Search key name',
            onFilter: (keyName: string, customerKey: IDisplayKey) =>
                customerKey.keyName.toLowerCase().includes(keyName.toLowerCase()),
        },
        {
            code: 'partnerOrClient',
            title: 'Partner/Client',
            dataIndex: 'partenOrClient',
            textSearchPlaceholder: 'Search partner/client',
            onFilter: (accountName: string, customerKey: IDisplayKey) =>
                customerKey.partenOrClient.toLowerCase().includes(accountName.toLowerCase()),
        },
        {
            code: 'dateUploadedFormatted',
            title: 'Date loaded',
            dataIndex: 'dateUploadedFormatted',
            sorter: (a, b) => a.dateUploaded.localeCompare(b.dateUploaded),
            defaultSortOrder: 'descend',
        },
        {
            code: 'action',
            title: 'Action',
            dataIndex: 'action',
            actions: [
                {
                    key: 'id',
                    label: 'Delete',
                    onClick: (id: number) => deleteKey(id, onDelete),
                },
            ],
        },
    ];

    const toListPage = () => setCurrentPage(KEY_INVENTORY_PAGE.LIST);

    const TableKeysComponent = observer(() => (
        <div className={manageKeyCss.key_inventory}>
            {errorMessage ? <DangerAlert message={errorMessage} className={manageKeyCss.alert} /> : null}
            <TableListingPage
                tableProps={{
                    title: '',
                    loadingState: keyStore.dataLoading,
                    table: {
                        columns,
                        dataSource: keyStore.displayKeys,
                        rowKey: 'id',
                    },
                    toolbar: {
                        onAdd,
                    },
                }}
            />
        </div>
    ));

    return currentPage === KEY_INVENTORY_PAGE.LIST ? (
        <TableKeysComponent />
    ) : (
        <UploadKeysComponent onCancel={toListPage} onOk={toListPage} />
    );
});
