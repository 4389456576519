import { IApiClientOptions } from 'core';
import { IKeyDistribution } from 'store/devices-store';

import { ICustomerKey, IUploadCustomerKeyOptions, IDistributeKeyRequestOptions, IDistributeKeyResponseResult, IKcvPspKeyResponse, PSP, IKeyTypeSupportMapped } from '../dto/key-management/key-dto';
import { AssureBaseApiClient, HTTP_METHOD } from './assure-base-api-client';

export class KeyApiClient extends AssureBaseApiClient {
    constructor(options: IApiClientOptions) {
        super('key', options);
    }

    protected getRootPath(): string {
        return '/keys';
    }

    public async getInventoryKeys(): Promise<ICustomerKey[]> {
        const responseResult = await this.getAllEntities<{ keys: ICustomerKey[] }>();
        return responseResult.keys;
    }

    public async createKeys(key: IUploadCustomerKeyOptions): Promise<boolean> {
        const responseResult = await this.send<{ result: boolean }>(HTTP_METHOD.POST, this.getRequestOptions('store', { ...key }));
        return responseResult.result;
    }

    public async deleteKey(id: number): Promise<boolean> {
        const response = await this.deleteEntity<{ result: boolean }>({ id });
        return response.result;
    }

    public async distributeKey(options: IDistributeKeyRequestOptions): Promise<{ result: IDistributeKeyResponseResult[] }> {
        return this.send<{result: IDistributeKeyResponseResult[]}>(HTTP_METHOD.POST, this.getRequestOptions('distribute', options));
    }

    public async getKeyInventory(accountId: number, deviceIdentifier: string): Promise<IKeyDistribution> {
        return this.send<IKeyDistribution>(HTTP_METHOD.GET, this.getRequestOptions('inventory', { accountId, deviceIdentifier }));
    }

    public getKcvPspKeys(accountUuid: string, psp: string): Promise<IKcvPspKeyResponse> {
        return this.send<IKcvPspKeyResponse>(HTTP_METHOD.GET, this.getRequestOptions('kcv', { accountUuid, psp }));
    }

    public getSupportedKeyTypes(): Promise<IKeyTypeSupportMapped> {
        return this.send(HTTP_METHOD.GET, this.getRequestOptions('supported-key-types'));
    }

    public async getPspTypesOfAccount(accountUuid: string): Promise<PSP[]> {
        const result = await this.send<{ pspTypes: string[] }>(HTTP_METHOD.GET, this.getRequestOptions('psp-types', { accountUuid }));
        return result.pspTypes?.filter<PSP>((item: string): item is PSP => Object.values(PSP).some(value => value === item));
    }
}
