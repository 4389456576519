import { computed } from 'mobx';

import { KeyApiClient } from '../../api/key-api-client';
import {
    ICustomerKey,
    IDistributeKeyRequestOptions,
    IDistributeKeyResponseResult,
    IUploadCustomerKeyOptions,
    IKcvPspKeyResponse,
    KeyType,
    PSP,
} from '../../dto/key-management/key-dto';

import { AssureBaseStore, IAssureStoreConstructorOptions } from '../assure-base.store';

import { formatDate } from 'common-utils/date-utils';
import { getErrorMessage } from 'common-utils';

const UPLOAD_KEY_TYPES = [
    { value: KeyType.BDK, label: 'BDK' },
    { value: KeyType.LHMAC, label: 'List HMAC' },
    { value: KeyType.KSID, label: 'Key Set ID' },
    { value: KeyType.KEY_TABLE_KEY, label: 'Key Table Key' },
];

export interface IDisplayKey {
    id: number;
    psp: PSP;
    keyId: number;
    keyName: string;
    partenOrClient: string;
    dateUploaded: string | Date;
    dateUploadedFormatted: string;
}

export class KeyStore extends AssureBaseStore<KeyApiClient, ICustomerKey> {
    public constructor(options: IAssureStoreConstructorOptions) {
        super(options);
    }

    protected get apiClient(): KeyApiClient {
        return this.apiClientStore.apiClients.key;
    }

    public async loadKeys(force?: boolean): Promise<void> {
        if (!force && this.hasEntities()) return;
        try {
            this.setDataLoading(true);
            this.setError(undefined);
            this.setEntities(await this.apiClient.getInventoryKeys());
        } catch (err) {
            this.setError(getErrorMessage(err));
        } finally {
            this.setDataLoading(false);
        }
    }

    @computed
    public get displayKeys(): IDisplayKey[] {
        if (!this.hasEntities()) return [];
        const clientKeys = this.entities.filter(item => item.clientAccount);
        const clientToKeySetID = clientKeys.reduce((map, item) => {
            if (item.keyType == KeyType.KSID) {
                map.set(item.clientAccount.id, item.id);
            }
            return map;
        }, new Map<number, number>());

        return clientKeys.map((key) => {
            return {
                id: key.id,
                psp: key.psp,
                keyId: clientToKeySetID.get(key.clientAccount?.id),
                keyName: key.keyName,
                partenOrClient: key.clientAccount?.name,
                dateUploaded: key.dateUploaded,
                dateUploadedFormatted: formatDate(key.dateUploaded.toLocaleString()),
            };
        });
    }

    public get uploadKeyTypes(): { value: string; label: string }[] {
        return UPLOAD_KEY_TYPES;
    }

    public getKeyTypeLabel(type: KeyType): string {
        return UPLOAD_KEY_TYPES.find((key) => key.value === type)?.label;
    }

    public async createKeys(keyUpload: IUploadCustomerKeyOptions): Promise<boolean> {
        const result = await this.apiClient.createKeys(keyUpload);
        await this.loadKeys(true);
        return result;
    }

    public async deleteKey(id: number): Promise<boolean> {
        const result = await this.apiClient.deleteKey(id);
        if (result) {
            this.removeEntity(id);
        }
        return result;
    }

    public distributeKey(options: IDistributeKeyRequestOptions): Promise<{ result: IDistributeKeyResponseResult[] }> {
        return this.apiClient.distributeKey(options);
    }

    public async getKcvPspKeys(account: string, psp: string): Promise<IKcvPspKeyResponse> {
        return this.apiClient.getKcvPspKeys(account, psp);
    }

    public async getPspTypesOfAccount(accountUuid: string): Promise<PSP[]> {
        return this.apiClient.getPspTypesOfAccount(accountUuid);
    }

    public async getSupportedKeyTypes(): Promise<{ [key in string]: string[] }> {
        const result = await this.apiClient.getSupportedKeyTypes();
        return Object.keys(result).reduce<{ [key in string]: string[] }>((objectResult, psp) => {
            const keyTypes = result[psp];
            objectResult[psp] = keyTypes.map(item => item.name);
            return objectResult;
        }, {});
    }
}
